import '../styles/styles.scss';

import React from 'react';

import Layout from '../components/Layout/layout';
import SEO from '../components/seo';

if (typeof window !== 'undefined') {
  // Make scroll behavior of internal links smooth
  require('smooth-scroll')('a[href*="#"]');
}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
  </Layout>
);

export default IndexPage;
